import { Link, graphql } from 'gatsby'

import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import React from 'react'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title

    const titleHeader = `Simiancraft does ${tag}.`

    const postPreview = posts.map(post => (
      <div className="project-tile" key={post.node.fields.slug}>
        <Link to={post.node.fields.slug}>
          <div className="project-info">
            <span className="is-size-2">{post.node.frontmatter.title}</span>
            <span>{post.node.frontmatter.date}</span>
            <span>{post.node.frontmatter.templateKey}</span>
          </div>
        </Link>
      </div>
    ))

    return (
      <Layout>
        <section className="section">
          <Helmet
            title={`${tag} | ${title}`}
            content={`Case studies and Projects related to our ${tag} work`}
          />
          <div className="container content inner">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h1
                    className="title is-size-4 is-bold-light"
                    style={{ margin: '1rem 2rem' }}
                  >
                    {titleHeader}
                  </h1>
                  <a href="#contact" className="contact button next scrolly">
                    Schedule a Call
                  </a>
                </div>

                <div className="project-tiles">{postPreview}</div>

                <p>
                  <Link to="/work/">Browse all works</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const projectByTagPageQuery = graphql`
  query projectsByTagsPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            isFeaturedPiece
          }
        }
      }
    }
  }
`
